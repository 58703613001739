<template>
  <v-responsive class="j-text-field text-start">
    <general-text
      v-if="componentProps.label"
      :text="componentProps.label!"
      class="j-text--fs-12 mb-1"
    />
    <v-text-field
      hide-details="auto"
      :type="componentProps.type"
      :variant="componentProps.variant"
      :bg-color="bgColor"
      :rounded="componentProps.rounded"
      :density="componentProps.density"
      :prepend-inner-icon="componentProps.prependInnerIcon"
      :placeholder="componentProps.placeholder"
      :value="componentProps.modelValue"
      :error-messages="componentProps.errorMessages"
      :rules="(componentProps.rules as string[])"
      :readonly="componentProps.readonly"
      :disabled="componentProps.disabled"
      :prefix="componentProps.prefix"
      :maxlength="maxLength"
      :style="{ '--autofill-background-color': bgColor }"
      @update:model-value="updateModelValue"
      @update:focused="updateFocused"
      @click:append-inner="onAppendInnerClick"
    >
      <template
        v-if="componentProps.appendInnerIcon"
        #append-inner
      >
        <v-btn
          variant="text"
          width="auto"
          height="auto"
          :icon="componentProps.appendInnerIcon"
          :color="componentProps.appendInnerIconColor"
          @click="onAppendInnerClick"
        />
      </template>
    </v-text-field>
  </v-responsive>
</template>

<script setup lang="ts">
import type {
  Variant,
  Density,
} from '~/types/components/General/TextField';

const componentProps = defineProps({
  label: {
    type: String,
    default: '',
  },
  variant: {
    type: String as () => Variant,
    default: 'solo' as Variant,
  },
  bgColor: {
    type: String,
    default: '',
  },
  rounded: {
    type: String,
    default: '4',
  },
  density: {
    type: String as () => Density,
    default: 'compact' as Density,
  },
  modelValue: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'text',
  },
  appendInnerIcon: {
    type: String,
    default: '',
  },
  prependInnerIcon: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  appendInnerIconColor: {
    type: String,
    default: '',
  },
  errorMessages: {
    type: String,
    default: '',
  },
  rules: {
    type: Array,
    default: () => [],
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  prefix: {
    type: String,
    default: '',
  },
  maxLength: {
    type: Number,
    default: null,
  },
});
const emit = defineEmits([
  'update:modelValue',
  'update:focused',
  'click:append-inner',
]);
const scssVariables = useScssVariables();
const bgColor = computed(() => componentProps.bgColor || scssVariables.jColorInputBg);
const updateModelValue = (modelValue: string) => emit('update:modelValue', modelValue);
const updateFocused = (event: boolean) => emit('update:focused', event);
const onAppendInnerClick = () => emit('click:append-inner');
</script>

<style scoped lang="scss">
.j-text-field {
  --autofill-background-color: initial;

  :deep(.v-input) {
    .v-field {
      border: 1px solid transparent
    }
    .v-field__append-inner {
      color: $j-text-secondary;
    }
    .v-text-field__prefix {
      font-size: $j-form-control-font-size;
      opacity: 1;
      line-height: normal;
      min-height: initial;
    }
    .v-field__input {
      font-size: $j-form-control-font-size;
      line-height: normal;
    }
    .v-input__details {
      padding: 12px 0 0;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    input:autofill {
      box-shadow: 0 0 0px 1000px var(--autofill-background-color) inset !important;
      -webkit-box-shadow: 0 0 0px 1000px var(--autofill-background-color) inset !important;
      -webkit-text-fill-color: $j-text-regular !important;
      caret-color: $j-text-regular !important;
      transition: background-color 5000s ease-in-out 0s;
    }
    :-moz-autofill {
      box-shadow: 0 0 0px 1000px var(--autofill-background-color) inset !important;
      background-color: var(--autofill-background-color) !important;
      color: $j-text-regular !important;
    }
  }
  :deep(.v-input--error:not(.v-input--disabled)) {
    .v-field {
      border-color: $j-color-error
    }
    .v-field__input {
      color: $j-color-error;
    }
    .v-input__details .v-messages {
      color: $j-color-error;
      .v-messages__message {
        line-height: 16px;
      }
    }
  }
}
</style>
